import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import Typography from '@material-ui/core/Typography'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleImg,
	ArticlePage, ArticleQuote
} from '../components/common/Article'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'
import { graphql } from 'gatsby'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function PoorTimeManagement(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "How to Set Simple Time Management Goals That Boost Productivity";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"Simple Time Management Goals That Boost Productivity - " + productName}
				description={"Setting goals improves our productivity. We discuss how to set and achieve those goals."}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Time Management Goals"
					caption={<UnsplashPhotoCredit author="Isaac Smith" href="https://unsplash.com/@isaacmsmith"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>
					<ArticleCopy>
						A common New Year's Resolution is to go to the gym "from now on". One big
						reason some people only stick with this for a couple weeks is they've set
						the bar too high. They plan on going too frequently, and they get burned out
						with this rapid change. Is your ambition to go to the gym more, achieve time
						management goals, or create other new habits? It's important to target
						realistic objectives for your work and your life.
					</ArticleCopy>
					<ArticleCopy>
						<strong>This is the key part&mdash;It's a lifestyle change!</strong>
						{' '}
						For recurring goals, pick ones that you can do indefinitely. This is a part
						of your life now. With this paradigm shift, unsuccessful New Year's
						Resolutions will be a thing of the past.
					</ArticleCopy>

					<Typography variant="h2" gutterBottom>
						Why is it Important to Set Goals?
					</Typography>
					<ArticleCopy component="div">
						<ol>
							<li>
								<strong>Goals clear the path for your objectives.</strong>
								{' '}
								They give you a point on the horizon to work towards. They give you
								a drive to finish when you're almost to the finish line. Working
								towards a set goal gives a great sense of purpose.
							</li>
							<li>
								<strong>Goals help you meet deadlines.</strong>
								{' '}
								Once you've set your sights on a target, incremental goals give you
								an idea of whether you're on track or not. Now you'll be able to
								kick it into high gear when you need to, or readjust goal lines as
								necessary. You might be able to raise your goals or find yourself
								across the line earlier than expected!

								<ArticleImg
									alt="Meet Deadlines"
									caption={<UnsplashPhotoCredit author="Lance Grandahl" href="https://unsplash.com/@lg17"/>}
									imgData={data.paintedRunnersImg.childImageSharp.gatsbyImageData}
								/>
							</li>
							<li>
								<strong>Goals improve your productivity.</strong>
								{' '}
								With clear objectives, you won't waste time figuring out what you
								should be focusing on. And you won't waste effort walking in the
								wrong direction.
							</li>
						</ol>
					</ArticleCopy>

					<Typography variant="h2" gutterBottom>
						How to Set Time Management Goals
					</Typography>
					<ArticleCopy component="div">
						<ol>
							<li>
								<div>
									<strong>Use <AnchorLink href="https://en.wikipedia.org/wiki/SMART_criteria">SMART criteria</AnchorLink> to create goals.</strong>
									{' '}
									This is a great acronym to help you remember some useful criteria to aid in forming your targets.
								</div>
								<ArticleQuote
									cite={(<footer>&mdash;George T. Doran. <cite>"There's a S.M.A.R.T. way to write management's goals and objectives."</cite> Management Review, 70, 11, 1981, pp. 35–36.</footer>)}
								>
									<ul>
										<li>Specific &ndash; target a specific area for improvement.</li>
										<li>Measurable &ndash; quantify or at least suggest an indicator of progress.</li>
										<li>Assignable &ndash; specify who will do it.</li>
										<li>Realistic &ndash; state what results can realistically be achieved, given available resources.</li>
										<li>Time-related &ndash; specify when the result(s) can be achieved.</li>
									</ul>
								</ArticleQuote>
								<p>
									Not every goal needs to meet all five criteria, but try to meet
									them as much as reasonable. It's great to set your sights high
									on lofty goals. But try to keep a good balance with reality.
								</p>
							</li>
							<li>
								<strong>Set short-term and long-term goals.</strong>
								{' '}
								Setting intermediate goals will help you manage the progress of the
								long term goal. If your overall goal is to run a marathon, set
								incremental goals of running further at various steps of training.
								If your goal is to finish a project, set incremental goals to finish
								pieces of the project. In either case, your short-term goals will be
								an indicator of your long-term progress. Continue looking ahead, and
								make adjustments as necessary.
							</li>
							<li>
								<strong>Write down your goals.</strong>
								{' '}
								I know you might have a mind like a steel trap, but we're flawed
								humans. By writing down your goals, you'll have a history of your
								goals. By analyzing your history, you can discover areas that need
								improvement. And you'll also see the areas where you're being
								productive. This will also keep your goals consistent without having
								to rely on fuzzy human memories.
							</li>
						</ol>
					</ArticleCopy>

					<Typography variant="h2" gutterBottom>
						How to Achieve Time Management Goals
					</Typography>
					<ArticleCopy component="div">
						<ol>
							<li>
								<strong>Prioritize where you spend your time.</strong>
								{' '}
								You have a lot of important tasks. But if you can focus on one task
								at a time, you'll likely make better progress. Use a
								{' '}
								<RouterLink to="/timesheet-app/">timesheet app</RouterLink>
								{' '}
								to track your tasks. That'll make it more evident if you're
								switching between tasks too much.
							</li>
							<li>
								<strong>Allocate time limits for daily tasks.</strong>
								{' '}
								You likely have tasks that you need to maintain each day. Factor
								this time in when setting your goals so you can remain realistic.

								<ArticleImg
									alt="Allocate Time"
									caption={<UnsplashPhotoCredit author="Immo Wegmann" href="https://unsplash.com/@macroman"/>}
									imgData={data.hourglassImg.childImageSharp.gatsbyImageData}
								/>
							</li>
							<li>
								<strong>Understand when to say no, including to yourself.</strong>
								{' '}
								Don't overcommit. This isn't limited to others asking you to do
								something. You can also set your own goals too high and burn
								yourself out. Regardless, piling on extra tasks can reduce your
								productivity. This unsustainability may cause you to abandon some
								long-term objectives. If you're going to start lifting weights or
								running, consider starting with two days a week instead of seven.
							</li>
							<li>
								<strong>Eliminate distractions.</strong>
								{' '}
								Be aware of not only the distractions around you, but also your
								distracting habits. One of the more common distractions is our
								phones and the act of regularly checking social media. There are
								tools available to help prevent this from harming your
								concentration. Consider using
								{' '}
								<AnchorLink href="https://support.apple.com/en-us/HT208982">Apple's Screen Time</AnchorLink>
								{' '}
								or
								{' '}
								<AnchorLink href="https://wellbeing.google/">Android's Digital Wellbeing</AnchorLink>
								. For external distractions, try working in another area. Also,
								wearing headphones can help.
							</li>
						</ol>
					</ArticleCopy>


					<ArticleCopy>
						Don't wait until New Years rolls around to set long-term goals. Set your
						goals regularly. Are you reaching your current goals? Is your bar too low or
						too high? The more you achieve your time management goals, the better
						work/life balance you'll find.
						Browse our <RouterLink to="/time-management-101/">Time Management 101</RouterLink> guide for everything you need to know in one spot.
					</ArticleCopy>
				</ArticleBody>
			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/time-management-goals/isaac-smith-8XlMU62ii8I-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  paintedRunnersImg: file(relativePath: {eq: "images/articles/time-management-goals/lance-grandahl-OxXmASDLFjY-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 378, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  hourglassImg: file(relativePath: {eq: "images/articles/time-management-goals/immo-wegmann-0SO5dMWhBsk-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 370, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
